<template>
  <div>
    <c-table
      ref="applyTable"
      title="장비 대여신청 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="multiple"
      rowKey="sopEquipmentRequestItemId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'closedSpaceEquipApplyPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sopEquipmentRentId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '장비종류',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:90px',
            align: 'right',
            sortable: false,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'requestRentPeriodStr',
            field: 'requestRentPeriodStr',
            label: '대여신청기간',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.cse.request.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.popupParam.sopEquipmentRentId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sopEquipmentRentId: this.popupParam.sopEquipmentRentId
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data
        },);
      }
    },
    select() {
      let selectData = this.$refs['applyTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
